import Head from 'next/head';
import Image from 'next/image';
import React, {FC, useState} from 'react';

import {useMediaQuery} from '@mui/material';

import {TypoComponentsHeroHeaderWithBpr} from 'lib/api/strapi';

// Inside one of the components that loads on your page:
import {palette} from 'styles/mui/scss';

import {Box, Typography} from 'components/basic-components';
import {withErrorBoundary} from 'components/error/ErrorBoundary';
import ContentWrapper from 'components/layout-components/ContentWrapper/ContentWrapper';
import BPRWidget from 'components/section-components/bpr/BPRWidget';

import styles from './HeroHeaderWithBPR.module.scss';

const HeroHeaderWithBPR: FC<TypoComponentsHeroHeaderWithBpr> = ({
  slogan,
  sloganLevel,
  textColor,
  backgroundImageMobile,
  backgroundImageScreen,
  displayImageOverlay,
  imageOverlay,
}) => {
  const isDesktop = useMediaQuery('(min-width:770px)');

  const [isBPRActive, setIsBPRActive] = useState(false);
  const backgroundImageScreenUrl = `/_next/image/?url=${backgroundImageScreen?.data?.attributes?.url}&w=1920&q=75`;
  return (
    <ContentWrapper
      className={styles.wrapper}
      sx={{
        backgroundImage: {xxs: 'none', md: `url(${backgroundImageScreenUrl})`},
        boxShadow: displayImageOverlay
          ? `inset 0 0 0 1000vmax ${
              imageOverlay === 'strong'
                ? palette.colorGrey50
                : imageOverlay === 'light'
                ? palette.colorGrey35
                : null
            }`
          : null,
      }}
    >
      <Head>
        <link rel="preload" href={backgroundImageScreenUrl} as="image" />
      </Head>
      {!isDesktop ? (
        <Box sx={{position: 'relative',display: {md: 'none'}}}>
          {displayImageOverlay ? (
            <Box
              sx={{
                backgroundColor:
                  imageOverlay === 'strong'
                    ? palette.colorGrey50
                    : imageOverlay === 'light'
                    ? palette.colorGrey35
                    : null,
              }}
              className={styles.overlay}
            />
          ) : null}
          <Image
            src={backgroundImageMobile?.data?.attributes?.url}
            height={backgroundImageMobile?.data?.attributes?.height}
            width={backgroundImageMobile?.data?.attributes?.width}
            priority
            alt=""
            className={styles.image}
          />
          <Box className={styles.sloganWrapperMobile}>
            <Typography
              sx={{
                color:
                  textColor === 'black'
                    ? palette.colorBlack
                    : textColor === 'white'
                    ? palette.colorWhite
                    : null,
              }}
              component={sloganLevel}
              className={styles.slogan}
            >
              {slogan}
            </Typography>
          </Box>
        </Box>
      ) : null}
      <Box className={styles.bprWrapper}>
      {isDesktop ? <Box
          className={styles.bprDesktop}
          sx={{
            width: isBPRActive ? '100%' : '32%',
            display: {xxs: 'none', md: 'block'}
          }}
        >
          <BPRWidget scrollBox isBPRActive={isBPRActive} setIsBPRActive={setIsBPRActive} />
        </Box> : 
        <Box className={styles.bprMobile}>
          <BPRWidget
            variant="modal"
            isHeaderBPR
            isBPRActive={isBPRActive}
            setIsBPRActive={setIsBPRActive}
          />
        </Box> }
        <Box
          className={styles.sloganWrapper}
          sx={{
            width: {md: isBPRActive ? '0%' : '33%'},
            visibility: isBPRActive ? 'hidden' : 'visible',
            marginLeft: {md: isBPRActive ? 0 : '16px', xxl: isBPRActive ? 0 : '45px'},
          }}
        >
          <Typography
            sx={{
              color:
                textColor === 'black'
                  ? palette.colorBlack
                  : textColor === 'white'
                  ? palette.colorWhite
                  : null,
            }}
            className={styles.slogan}
          >
            {slogan}
          </Typography>
        </Box>
      </Box>
    </ContentWrapper>
  );
};

export default withErrorBoundary(HeroHeaderWithBPR, ' HeroHeaderWithBPR');
